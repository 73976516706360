@import "shared";

.Gallery {
    margin: px(190) 0;
    padding-bottom: px(80);
}

.heading {
    margin-left: px(180);
    width: px(420);

    @include bp(mobile) {
        width: calc(100% - var(--col-size));
        margin: 0 auto;
    }
}

.headingContainer {
    opacity: 0;
    z-index: 5;
    top: calc(var(--col-size-half) + var(--header-height));
}

.images {
    width: 100%;
    padding: 0 var(--col-size);

    @include bp(mobile) {
        padding: 0 var(--col-size-half);
    }
}

.imageItem {
    margin-top: px(80);
    margin-bottom: px(80);
    max-width: 60%;
    width: 100%;
    position: relative;
    overflow: hidden;
    opacity: 0;

    @include bp(mobile) {
        margin-top: px(50);
        margin-bottom: px(50);

        &:first-child {
            margin-top: px(25);
        }
    }

    &.is-portrait {
        max-width: 44%;

        @include bp(mobile) {
            max-width: 50%;
        }
    }

    &.is-landscape {
        max-width: 66.666666%;

        @include bp(mobile) {
            max-width: 90%;
        }
    }

    &.left {
    }

    &.center {
        margin-left: auto;
        margin-right: auto;
    }

    &.right {
        margin-left: auto;
    }
}

.image {
    width: 100%;
    object-fit: cover;
}
