@import "shared";

.BoxHeading {
    min-height: px(145);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    &.green {
        background-color: $green;
        color: $black;
    }

    &.red {
        background-color: $red;
        color: $off-white;
    }

    &.yellow {
        background-color: $yellow;
        color: $black;
    }

    &.blue {
        background-color: $blue;
        color: $off-white;
    }

    &.black {
        background-color: $black;
        color: $off-white;
    }

    &[data-title-size="small"] {
        align-items: flex-start;
        min-height: px(90);
    }

    &.hasIcon {
        display: grid;
        grid-template-columns: 1fr px(100);
    }
}

.boxHeading__title {
    white-space: break-spaces;
    padding: px(12) px(22);

    [data-title-size="large"] & {
        @include h4;
    }

    [data-title-size="small"] & {
        @include body;
        font-weight: 700;
    }
}

.boxHeading__iconBox {
    height: 100%;
    border-left: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.boxHeading__lottieContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
}
