@import "shared";

.GetInvolvedHero {
    padding-top: px(190);

    @include bp(mobile) {
        padding-top: px(107);
    }

    .titleTextBlock {
        margin-left: px(180);
        opacity: 0;
        width: px(420);

        @include bp(mobile) {
            margin-left: var(--col-size-half);
            width: calc(100% - calc(var(--col-size-half) * 2));
        }
    }
}

.verticalText {
    top: px(100);
    opacity: 0;

    @include bp(mobile) {
        display: none;
    }
}

.inner {
    position: relative;
}

.images {
    position: absolute;
    width: 40%;
    left: 55%;
    @include vh(100);
    top: 0;

    @include bp(mobile) {
        margin-top: px(75);
        position: relative;
        height: px(585);
        width: px(305);
        left: px(50);
    }
}

.imageTop {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: px(500);
    top: px(-50);

    @include bp(mobile) {
        width: 100%;
        top: 0;
    }
}

.imageMiddle {
    opacity: 0;
    position: absolute;
    width: px(190);
    left: 20%;

    @include bp(mobile) {
        width: px(130);
        top: px(60);
        left: 30%;
    }
}

.imageBottom {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: px(500);
    top: px(400);

    @include bp(mobile) {
        width: 100%;
        top: auto;
        bottom: 0;
    }
}
