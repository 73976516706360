@import "shared";

.BursariesDiversity {
    position: relative;
    z-index: 2;
    margin: px(130) 0 px(-20);

    @include bp(mobile) {
        margin: px(-150) 0 px(130);
    }
}

.inner {
    padding: 0 px(180) 0 px(80);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include bp(mobile) {
        padding: 0 var(--col-size);
        flex-direction: column;
    }
}

.headingDescriptionContainer {
    min-width: px(440);
    max-width: px(440);
    position: relative;

    @include bp(mobile) {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: px(80);
    }
}

.image {
    position: absolute;
    top: 200%;
    left: 60%;
    width: px(346);

    @include bp(mobile) {
        width: px(263);
        position: static;
        margin-bottom: px(60);
    }
}

.heading {
    @include h5;
    margin-bottom: px(15);
    white-space: break-spaces;
}

.description {
    @include body;
}

.listBlock {
    min-width: px(280);
    max-width: px(280);
    margin-top: px(225);

    @include bp(mobile) {
        margin-top: 0;
        margin-left: auto;
    }
}
