@import "shared";

.TitleTextBlock {
    width: 100%;
}

.boxHeading {
    &.sticky {
        position: sticky;
        top: calc(var(--col-size-half) + var(--header-height));
        z-index: 2;

        &.stuck {
            position: relative;
            top: auto;
            transform: translateY(
                calc(
                    var(--offset-distance) + 100% +
                        calc(var(--col-size-half) + var(--header-height))
                )
            );
        }
    }

    .hasBorder & {
        border: 1px solid $black;
    }
}

.content {
    position: relative;

    .hasBorder & {
        background-color: $off-white;
        border: 1px solid $black;
        margin-top: -1px;
    }

    padding: px(60) px(12);

    @include bp(mobile) {
        padding: px(30) px(12);
    }
}

.trackStopPosition {
    top: 30%;
    width: 100%;
    height: 1px;
    position: absolute;
}
