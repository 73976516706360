@import "shared";

$min-height-top-content: px(180);

@mixin eligibility-grid {
    display: grid;
    grid-template-columns: minmax(0, #{px(70)}) 1fr;
    gap: 1px;
}

.BursariesEligibility {
    margin: px(300) 0 px(150);

    @include bp(mobile) {
        margin: px(80) 0;
        position: relative;
        overflow: hidden;
    }
}

.inner {
    width: 100%;
    padding: 0 px(40);

    @include bp(mobile) {
        padding: 0 var(--col-size);
    }
}

.blocksContainer {
    display: grid;
    grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.5fr);
    gap: px(40);

    @include bp(mobile) {
        grid-template-columns: 1fr;
    }
}

.blocksColumn {
    position: relative;
}

.topContentLeft {
    padding: 0 px(40);
    min-height: $min-height-top-content;

    @include bp(mobile) {
        padding: 0 px(20);
        min-height: 0;
        margin-bottom: px(55);
    }
}

.topContentLeft__title {
    @include h5;
    margin-bottom: px(20);
}

.topContentLeft__description {
}

.circleDescriptionContainer {
    min-height: $min-height-top-content;
    position: relative;
    width: px(260);
    text-align: center;
    @include body-small;

    @include bp(mobile) {
        margin: px(110) auto px(130) auto;
        min-height: 0;
    }
}

.circle {
    position: absolute;
    width: px(500);
    left: px(-100);
    top: px(-190);

    @include bp(mobile) {
        width: px(410);
        left: px(-80);
        top: px(-150);
    }
}

.block {
    border: 1px solid $black;
    position: relative;
    z-index: 2;
}

.block__heading {
    min-height: px(226);
    border-bottom: 1px solid $black;

    @include bp(mobile) {
        min-height: 0;
    }
}

.block__content {
    background-color: $off-white;
}

.block__colHeadings {
    @include eligibility-grid;
    background-color: $off-white;
    z-index: 2;
    position: relative;
}

.block__colHeading {
    font-size: 8px;
    text-transform: uppercase;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 px(6);
    outline: 1px solid $black;
}

.block__colContent {
    display: flex;
    gap: 1px;
    flex-direction: column;
}

.block__colContentRow {
    @include eligibility-grid;
}

.block__col {
    outline: 1px solid $black;
    position: relative;

    &:nth-child(2) {
        padding: px(30) px(40);

        @include bp(mobile) {
            padding: px(20) px(10);
            @include body-small;
        }
    }
}

.block__col__lottieContainer {
    position: absolute;
    width: px(44);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.block__col {
}

.noteContent {
    padding-left: px(40);
    padding-right: px(30);
    position: relative;
    margin-top: px(50);

    @include bp(mobile) {
        padding-right: px(10);
    }
}

.noteContent__heading {
    @include body-large;
    color: $red;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.noteContent__headingIcon {
    width: px(20);
    position: absolute;
    top: px(5);
    left: 0;

    @include bp(mobile) {
        top: px(3);
    }
}

.noteContent__headingText {
}

.noteContent__text {
    @include body-small;
    margin-top: px(10);
}

.shape {
    width: px(560);
    position: absolute;
    top: 90%;
    left: -30%;

    @include bp(mobile) {
        width: px(294);
        position: relative;
        top: auto;
        left: 12%;
        margin-top: px(-130);
    }
}
