@import "shared";

.TicketPlans {
    padding: var(--col-size-half);
    position: relative;
    overflow: hidden;
}

.inner {
    border: 1px solid $black;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 2;
    position: relative;

    .hasTopSpacingBox & {
        @include vh(65);
        min-height: px(600);
        border: 1px solid $black;

        @include bp(mobile) {
            height: auto;
            min-height: 0;
            padding-top: px(100);
        }
    }

    @include bp(mobile) {
        min-height: 0;
        height: auto;
        align-items: flex-start;
    }
}

.verticalText {
    top: 30vh;
}

.sideText__char {
    width: 100%;
    margin: px(1) 0;
}

.plans {
    display: grid;
    gap: 1px;
    position: relative;
    z-index: 2;
    overflow: hidden;

    .hasTopSpacingBox & {
        padding-top: 2px;
    }

    &.col-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include bp(mobile) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &.col-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));

        @include bp(mobile) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}

.plans__item {
    outline: 1px solid $black;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateY(calc(100% + 2px));
}

.plans__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px(5) px(10);
    border-bottom: 1px solid $black;
    width: 100%;

    @include bp(mobile) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: px(80);
    }
}

.plans__itemTitle {
    @include body-large;
    white-space: break-spaces;

    @include bp(mobile) {
        line-height: 1.3;
        margin-bottom: px(3);
    }
}

.plans__itemPrice {
    font-size: px(16);
    text-transform: uppercase;
    font-weight: 700;
    color: $red;
}

.plans__itemContent {
}

.plans__itemContentList {
    margin: 0;
    padding: px(20) px(60) px(70);
    list-style: none;

    @include bp(mobile) {
        padding: px(10);
    }
}

.plans__itemContentListItem {
    @include body-small;

    &:not(:last-child) {
        margin-bottom: px(15);
    }
}

.linkContainer {
    width: 100%;
    margin-top: auto;

    .highlight {
        width: 130%;
    }
}

.link {
    background-color: $blue;
    color: $white;
    height: px(40);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px(14);

    .plans__itemIsHighlighted & {
        background-color: $red;
    }
}

.link__label {
    position: relative;
}

.graphicContainer {
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: px(-100);

    @include bp(mobile) {
        margin-top: px(-70);
    }
}

.graphic {
    max-width: 100%;
    z-index: 1;
    opacity: 0;
    width: px(650);
    margin-right: px(40);

    @include bp(mobile) {
        width: px(390);
        margin-right: px(-80);
    }
}
