@import "shared";

@mixin col-grid {
    display: grid;
    grid-template-columns: minmax(0, 0.6fr) minmax(0, 0.4fr);
    gap: 1px;
}

.BursariesAbout {
    margin: px(150) 0;
    position: relative;
    padding-bottom: px(140);

    @include bp(mobile) {
        margin: px(150) 0 px(100);
        padding-bottom: 0;
        overflow: hidden;
    }
}

.inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: px(65);
    position: relative;

    @include bp(mobile) {
        gap: px(0);
        flex-direction: column;
        padding: 0 var(--col-size-half);
    }
}

.cubeContainer {
    margin-left: px(80);
    min-width: px(480);
    max-width: px(480);

    @include bp(mobile) {
        margin-bottom: px(90);
        min-width: 90%;
        max-width: 90%;
        margin-left: auto;
    }
}

.cubeFront {
    @include body-large;
    padding: px(13) px(40);
    background-color: $off-white;

    @include bp(mobile) {
        @include body;
        padding: px(18) px(20);
    }
}

.cubeBack {
    @include position-100(absolute);
    background-color: $red;
}

.slidingScaleContainer {
    min-width: px(540);
    max-width: px(540);

    @include bp(mobile) {
        min-width: calc(100% - var(--col-size));
        max-width: calc(100% - var(--col-size));
        margin: 0 auto;
    }
}

.slidingScaleContainer__header {
    padding: px(9) px(10) px(34);
    background-color: $red;
    border: 1px solid $black;
}

.slidingScaleContainer__heading {
    @include body-large;
    color: $white;
    white-space: break-spaces;
}

.slidingScaleContainer__content {
    border: 1px solid $black;
    margin-top: -1px;
    background-color: $off-white;
}

.slidingScaleContainer__colHeadings {
    @include col-grid;
    z-index: 2;
    position: relative;
    background-color: $off-white;
}

.slidingScaleContainer__colHeading {
    font-size: 8px;
    text-transform: uppercase;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 px(6);
    outline: 1px solid $black;
}

.slidingScaleContainer__contentValueRows {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.slidingScaleContainer__contentValues {
    @include col-grid;
}

.slidingScaleContainer__col1,
.slidingScaleContainer__col2 {
    outline: 1px solid $black;
    padding: px(13) px(6);
}

.slidingScaleContainer__col2 {
}

.equationImageContainer {
    width: px(413);
    top: 105%;
    left: 55%;
    position: absolute;

    @include bp(mobile) {
        position: static;
        width: px(263);
        margin-left: px(20);
        margin-top: px(40);
    }
}

.equationImage {
}
