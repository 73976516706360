@import "shared";

.RichText {
    &[data-content-size="large"] {
        @include body-large;
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    ul {
        margin: px(15) 0 px(15) px(15);
        list-style: initial;
    }

    li {
        &:not(:last-child) {
            margin-bottom: px(10);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: px(20) 0;
    }

    h5 {
        font-size: px(24);
        line-height: 1.3;

        @include bp(mobile) {
            font-size: px(16);
            line-height: 1.3;
        }
    }

    p {
        margin: px(15) 0;
    }

    a {
        text-decoration: underline;
    }
}
