@import "shared";

.AboutHero {
}

.inner {
    position: relative;
}

.introTextBlockContainer {
    width: px(421);
    margin-left: px(180);
    margin-top: px(151);
    opacity: 0;
    position: relative;

    @include bp(mobile) {
        width: calc(100% - var(--col-size));
        margin-left: var(--col-size-half);
        margin-top: px(107);
    }
}

.introBlock {
}

.rectangeText {
    margin-top: px(20);

    @include bp(mobile) {
        width: 90%;
    }
}

.images {
    position: absolute;
    top: px(-30);
    left: 130%;
    width: px(600);

    @include bp(mobile) {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: px(520);
    }
}

.imageTall {
    width: px(216);
    top: 0;
    left: 0;
    position: absolute;

    @include bp(mobile) {
        width: px(146);
    }
}

.imageLines {
    width: 110%;
    right: 0;
    top: px(280);
    position: absolute;

    @include bp(mobile) {
        width: 95%;
        top: px(180);
        margin-right: calc(var(--col-size-half) * -1);
    }
}

.imageDiamond {
    width: px(380);
    top: px(500);
    left: 20%;
    position: absolute;

    @include bp(mobile) {
        left: auto;
        right: px(22);
        width: px(230);
        top: px(350);
    }
}

.aboutTextBlockContainer {
    margin-left: px(634);
    width: px(445);

    @include bp(mobile) {
        width: calc(100% - var(--col-size));
        margin-left: var(--col-size-half);
        margin-top: px(50);
    }
}

.aboutBlock {
}
