@import "shared";

.CtaSection {
    @include grid;
    @include vh(100);
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        height: auto;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: px(100);
        padding-bottom: px(80);
    }
}

.circle {
    width: 80%;
    position: absolute;
    left: -10%;
    top: 35%;
    z-index: 1;
    color: $grey-1;
    pointer-events: none;
}

/* ============================================
Weird Shape
============================================ */

.WeirdShape {
    width: px(360);
    height: px(562);
    position: relative;
    grid-column: 4 / 9;
    z-index: 2;

    --offset: #{px(20)};

    @include bp(mobile) {
        width: px(215);
        height: px(336);
        margin-left: var(--col-size);
        --offset: var(--col-size-half);
        margin-bottom: px(60);
    }

    path {
        fill: transparent;
    }
}

.weirdShape__side {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.weirdShape__front {
    transform: translate(calc(var(--offset) * -1), var(--offset));
    z-index: 5;
    pointer-events: none;
}

.weirdShape__middle {
    z-index: 3;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    path {
        fill: $blue;
    }
}

.weirdShape__back {
    transform: translate(var(--offset), calc(var(--offset) * -1));
    z-index: 1;
    pointer-events: none;
}

.weirdShape__left,
.weirdShape__right {
    border-top: 1.5px solid $black;
    border-bottom: 1.5px solid $black;
    width: calc(var(--offset) * 2);
    left: calc(var(--offset) * -1);
    z-index: 4;
    transform: skewY(-45deg);
    pointer-events: none;
}

.weirdShape__left {
    z-index: 2;
}

.weirdShape__right {
    left: auto;
    right: calc(var(--offset) * -1);
}

/* ============================================
Rect
============================================ */

.RectShape {
    // width: px(360);
    height: fit-content;
    position: relative;
    z-index: 3;
    grid-column: 16 / 27;

    --offset: #{px(20)};

    @include bp(mobile) {
        width: px(258);
        margin-left: var(--col-size);
        --offset: var(--col-size-half);
    }

    path {
        fill: transparent;
    }
}

.rectShape__side {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.rectShape__front {
    transform: translate(calc(var(--offset) * -1), var(--offset));
    z-index: 5;
    pointer-events: none;
    border: 1px solid $black;
}

.rectShape__middle {
    z-index: 3;
    position: relative;
    border: 1px solid $black;
    background-color: $red;
    padding: px(13) px(86) px(13) px(13);

    @include bp(mobile) {
        padding: px(13);
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    path {
        fill: $blue;
    }
}

.rectShape__back {
    transform: translate(var(--offset), calc(var(--offset) * -1));
    z-index: 1;
    pointer-events: none;
    border: 1px solid $black;
}

.rectShape__left,
.rectShape__right {
    border-top: 1.5px solid $black;
    border-bottom: 1.5px solid $black;
    width: calc(var(--offset) * 2);
    left: calc(var(--offset) * -1);
    z-index: 4;
    transform: skewY(-45deg);
    pointer-events: none;
}

.rectShape__left {
    z-index: 2;
}

.rectShape__right {
    left: auto;
    right: calc(var(--offset) * -1);
}

/* ============================================
CTA Section Content
============================================ */

.sectionContent {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    color: $white;
}

.sectionContent__title {
    @include h4;
    margin-bottom: px(20);
    color: $off-white;

    @include bp(mobile) {
        font-size: px(24);
    }
}

.sectionContent__cta {
    @include body;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    @include bp(mobile) {
        font-size: px(8);
    }

    path {
        fill: $black;
    }

    svg {
        position: static;
        width: px(13);
    }
}

.sectionContent__link {
    background-color: $off-white;
    color: $black;
    padding: px(9) px(18);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionContent__linkText {
    display: block;
    margin-right: px(40);

    @include bp(mobile) {
        margin-right: px(20);
    }
}

.sectionContent__highlight {
    position: absolute;
    top: 50%;
    left: 0%;
    max-width: 100%;
    pointer-events: none;
    transition: 0.1s;
    transform: translateY(-50%);
    transform-origin: right center;
    transition: clip-path 0.15s;
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}

.sectionContent__link {
    @include hover {
        .sectionContent__highlight {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }
}

.link {
    @include hover {
        .link__labelHighlight {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }
}

.weirdShape__content {
    padding: px(114) px(10) px(40);

    @include bp(mobile) {
        padding: px(60) px(10) px(40);
    }
}
