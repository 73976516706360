@import "shared";

.GetInvolvedPartnerHostSection {
    position: relative;
    z-index: 2;

    .titleTextBlock {
        margin-left: px(727);
        margin-top: px(-100);
        width: px(420);

        @include bp(mobile) {
            margin-left: 0;
            width: calc(100% - var(--col-size));
        }
    }
}
.inner {
    @include bp(mobile) {
        padding-top: px(200);
    }
}

.topSection {
    @include bp(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.leftImages {
    position: absolute;
    height: px(546);
    width: px(445);
    margin-left: px(150);
    margin-top: px(200);
    pointer-events: none;

    @include bp(mobile) {
        position: relative;
        width: px(240);
        height: px(294);
        order: 2;
        margin-left: px(-30);
        margin-top: px(-100);
    }
}

.equationImage {
    width: px(390);
    bottom: px(40);
    left: px(-100);
    position: absolute;
    z-index: 2;

    @include bp(mobile) {
        width: px(224);
    }
}

.lineImage {
    @include position-100(absolute);
    object-fit: contain;
}

.cubeSection {
    position: relative;
}

.cubeContainer {
    @include vh(100);
    display: flex;
    align-items: center;
}

.cube {
    width: px(520);
    --box-offset: 0;
    margin-left: px(200);

    @include bp(mobile) {
        width: px(319);
        margin-left: px(10);
    }
}

.cube__frontText {
    background-color: $off-white;
    padding: px(30) px(90) px(30) px(40);

    @include bp(mobile) {
        padding: px(36) px(10);
    }

    [data-component="RichText"] {
        @include bp(mobile) {
            @include body-small;
        }
    }
    ul {
        padding-left: px(45);

        @include bp(mobile) {
            padding-left: px(25);
        }
    }
}

.cube__frontHeading {
    @include body-large;
    text-transform: uppercase;
    margin-bottom: px(11);
}

.cube__backContent {
    background-color: $blue;
    @include position-100(absolute);
}

.geometryImage {
    width: px(500);
    margin-left: px(750);

    @include bp(mobile) {
        width: px(300);
        margin-left: px(58);
        margin-top: 0;
    }
}

.lowerEquationImage {
    position: absolute;
    width: px(580);
    right: px(-120);
    top: 50%;

    @include bp(mobile) {
        position: static;
        width: px(332);
        margin-left: px(10);
    }
}
