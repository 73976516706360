@import "shared";

.HomeScheduleSection {
    @include grid;
    position: relative;
    height: 100vh;
    align-items: center;

    --box-offset: #{px(0)};
    --box-angle: -45deg;
    --box-y-offset: #{px(0)};

    @include bp(mobile) {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.inViewTrigger {
    position: absolute;
    top: 0;
    left: 0;
}

.mobileScheduleContainer {
    width: 100%;
}

.polygon {
    position: absolute;
    top: 100%;
    left: 50%;
    width: px(500);
    color: $grey-1;

    @include bp(mobile) {
        display: none;
    }
}

.textBoxContainer {
    z-index: 2;
    grid-column: 14 / 28;

    @include bp(mobile) {
        width: 80vw;
        @include vh(100);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.textBox {
    position: relative;

    @include bp(mobile) {
        max-width: 80%;
    }
}

.textBox__frontText {
    padding: px(35) px(45);
    background-color: $off-white;

    @include bp(mobile) {
        padding: px(22);
    }
}

.textBox__frontHeading {
    @include body;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: px(10);
}

.textBox__frontDescription {
    @include body-large;
}

.textBox__backImage {
    @include position-100(absolute);
    object-fit: cover;
    max-width: 100%;
    z-index: 2;

    img {
        height: 100%;
    }
}

.textBox__backColor {
    @include position-100(absolute);
    background-color: $yellow;
}

.textBox__parallaxImages {
    @include position-100(absolute);

    @include bp(mobile) {
        display: none;
    }
}

.parallaxImage {
    position: absolute;
    opacity: 0.5;
}

.parallaxImage1 {
    top: 10%;
    left: 5%;
    width: px(400);
}

.parallaxImage2 {
    top: 80%;
    left: px(-50);
    width: px(240);
}

.parallaxImage3 {
    top: 72%;
    right: 0%;
    left: auto;
    width: px(550);
}

.schedule {
    grid-column: 2 / 9;

    &.mobile {
        width: px(280);
        margin-left: var(--col-size-half);
        order: 3;
    }

    &.desktop {
    }
}
