@import "shared";

.AboutBoxSection {
}

.inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include bp(mobile) {
        flex-direction: column;
    }
}

.image {
    width: px(449);
    margin-left: px(56);

    @include bp(mobile) {
        width: px(247);
        margin-left: var(--col-size-half);
        margin-top: px(80);
    }
}

.cube {
    width: px(540);
    margin-left: px(40);
    margin-top: px(180);

    @include bp(mobile) {
        width: px(320);
        margin-top: px(50);
        margin-left: px(36);
    }
}

.cubeFront {
    padding: px(36) px(44);
    background-color: $off-white;

    @include bp(mobile) {
        padding: px(25);
    }
}

.heading {
    @include body;
    margin: 0 0 px(10);
}

.rta {
    @include body-large;
    font-weight: 700;

    @include bp(mobile) {
        font-size: px(16);
    }
}

.cubeBack {
    background-color: $blue-light;
    @include position-100(absolute);
}
