@import "shared";

@mixin col-grid {
    display: grid;
    grid-template-columns:
        minmax(0, 0.17fr) minmax(0, 0.12fr) minmax(0, 0.2fr) minmax(0, 0.32fr)
        minmax(0, 0.19fr);
}

.ScheduleSection {
    margin: 0 0 px(110);
    padding: px(110) var(--col-size-half) 0;

    --nav-button-height: #{px(40)};
    --col-padding: #{px(20)};

    &.formattingContent {
        min-height: 100vh;
    }

    @include bp(mobile) {
        margin: 0 0 px(80);
    }
}

.inner {
}

.boxHeadingContainer {
    opacity: 0;
}

.boxHeading {
    width: px(422);
    height: px(234);
    margin-bottom: px(110);
    margin-left: px(160);

    @include bp(mobile) {
        width: 100%;
        height: px(123);
        margin-left: 0;
        margin-bottom: px(180);
    }
}

/* ==================================
Schedule main container
================================== */

.scheduleContainer {
    position: relative;
}

.imagesContainer {
    position: absolute;
    width: 50%;
    top: px(-300);
    left: 50%;

    @include bp(mobile) {
        top: px(-170);
        width: 100%;
        left: 0;
    }
}

.narrowImageContainer {
    opacity: 0;
}

.narrowImage {
    width: px(300);
    left: 0;
    top: 0;
    position: absolute;

    @include bp(mobile) {
        width: px(185);
        left: px(30);
    }
}

.wideImageContainer {
    opacity: 0;
}

.wideImage {
    width: px(420);
    left: px(190);
    top: px(130);
    position: absolute;
    z-index: 2;

    @include bp(mobile) {
        width: px(189);
        left: px(143);
        top: px(60);
    }
}

/* ==================================
Nav Container
================================== */

.scheduleNavigationContainer {
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(var(--schedule-columns), minmax(0, 1fr));
    gap: px(20);
    opacity: 0;

    @include bp(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        gap: 0;
    }
}

.scheduleTypeDropdown {
    position: relative;

    @include bp(mobile) {
        min-width: calc(100% + 2px);
        margin-bottom: px(10);
    }
}

.scheduleTypeDropdownButton {
    height: var(--nav-button-height);
    background-color: $yellow;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 px(14);
    position: relative;
    cursor: pointer;
    width: 100%;
    border-radius: none;
    color: $black;

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: px(7) solid transparent;
        border-right: px(7) solid transparent;
        border-top: px(11) solid $black;
    }
}

.scheduleTypeText {
    text-transform: uppercase;
    @include eyebrow;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.scheduleTypeDropdownList {
    position: absolute;
    top: 100%;
    left: 1px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: calc(100% - 2px);
    background-color: $off-white;
}

.scheduleTypeDropdownListItem {
    display: grid;
    grid-template-columns: minmax(0, #{px(40)}) minmax(0, 1fr);
    gap: 1px;
    width: 100%;

    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.scheduleTypeDropdownListItemLottieContainer {
    outline: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: px(50);
    position: relative;

    & > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65%;
    }
}

.scheduleTypeDropdownListItem {
    @include hover {
        .scheduleTypeDropdownListItemLottieContainer {
            background-color: $yellow;
        }
    }
}

.scheduleTypeDropdownListItemText {
    outline: 1px solid $black;
    display: flex;
    align-items: center;
    justify-self: flex-start;
    width: 100%;
    @include eyebrow;
    font-weight: 400;
    padding: 0 px(10);
}

.daySelector {
    height: var(--nav-button-height);
    background-color: $off-white;
    outline: 1px solid $black;
    border: none;
    @include eyebrow;
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    border-radius: none;
    color: $black;

    &.isActive,
    &:focus,
    &:active {
        background-color: $yellow !important;
        outline: 1px solid $yellow !important;
    }

    @include bp(mobile) {
        width: var(--button-width-mobile);
    }
}

/* ==================================
Content
================================== */

.scheduleContentContainer {
    opacity: 0;
    position: relative;
    z-index: 4;
    margin-top: var(--col-padding);
    background-color: $off-white;

    @include bp(mobile) {
        margin-top: px(50);
    }
}

.scheduleContentColHeadings {
    @include col-grid;
    font-size: px(8);
    line-height: 1;
    text-transform: uppercase;
    gap: 1px;

    @include bp(mobile) {
        display: none;
    }
}

.scheduleContentColHeading {
    padding: 0 var(--col-padding);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--col-padding);
    outline: 1px solid $black;
}

.scheduleContent {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

/* ==================================
Schedule Item
================================== */

.scheduleItem {
    background-color: $off-white;
    width: 100%;

    @include bp(mobile) {
        outline: 1px solid $black;
    }

    &.isOpen {
        outline: 1px solid $black;
    }

    &:not(.clickable) {
        pointer-events: none;
    }
}

.scheduleItemColumns {
    @include col-grid;
    gap: 1px;
    position: relative;

    @include bp(mobile) {
        display: block;
        padding: var(--col-padding);
        position: relative;
    }

    & > div {
        padding: px(12) 0 0 var(--col-padding);
        background-color: $off-white;
        height: px(45);
        display: flex;
        align-items: flex-start;
        outline: 1px solid $black;
        cursor: pointer;

        @include bp(mobile) {
            outline: none;
            padding: 0;
            height: auto;
        }
    }
}

.highlight {
    top: px(25);

    @include bp(mobile) {
        display: none;
    }
}

.isOpen .scheduleItemColumns > div {
    outline: none;
    height: auto;
}

.scheduleItemColumnHeading {
    @include body;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;

    @include bp(mobile) {
        font-size: px(14);
        white-space: initial;
        overflow: visible;
    }

    .isOpen & {
        white-space: normal;
    }
}

.scheduleItemLocation {
    text-transform: uppercase;

    @include bp(mobile) {
        max-width: 70%;
        margin-bottom: px(14);
    }

    :not(.clickable) & {
        @include bp(mobile) {
            margin-bottom: 0;
        }
    }
}

.scheduleItemTime {
    color: $red;

    @include bp(mobile) {
        position: absolute;
        top: var(--col-padding);
        right: var(--col-padding);
    }
}

.scheduleItemWorkshop {
    @include bp(mobile) {
        margin-bottom: px(14);
    }

    :not(.clickable) & {
        @include bp(mobile) {
            max-width: 65%;
        }
    }
}

.scheduleItemSpeakers {
    @include bp(mobile) {
        margin-bottom: px(14);

        * {
            font-weight: 300;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.ticketAccess {
}

.scheduleItemContent {
    display: none;
    padding: var(--col-padding);
    grid-template-columns: minmax(0, 0.492fr) minmax(0, 0.5fr);
}

.scheduleItem.isOpen .scheduleItemContent {
    display: grid;

    @include bp(mobile) {
        display: block;
    }
}

.scheduleItemContent__imageContainer {
}

.scheduleItemContent__image {
    max-width: 350px;
    width: 100%;

    @include bp(mobile) {
        max-width: 50%;
    }
}

.scheduleItemContent__descriptionContainer {
    padding-left: var(--col-padding);

    @include bp(mobile) {
        font-size: px(14);
        margin-top: px(20);
        padding-left: 0;
    }
}

.scheduleItemContent__description {
    p {
        margin: px(20) 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
