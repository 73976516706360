@import "shared";

.AboutEdmontonSection {
    margin-top: px(140);
    position: relative;
    overflow: hidden;
}

.inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: px(961);
    margin-left: px(180);
    gap: px(80);

    @include bp(mobile) {
        gap: 0;
        grid-template-columns: 1fr;
        width: 100%;
        margin-left: 0;
    }
}

.col1 {
    display: flex;
    flex-direction: column;
    gap: px(50);

    @include bp(mobile) {
        gap: px(30);
    }
}

.introBlock {
    @include bp(mobile) {
        margin: 0 auto;
        width: calc(100% - var(--col-size));
    }
}

.howToGetThereBlock {
    @include bp(mobile) {
        margin: 0 auto;
        width: calc(100% - calc(var(--col-size) * 2));
    }
}

.rectangleBlock {
    @include bp(mobile) {
        margin-left: var(--col-size);
        width: calc(100% - calc(var(--col-size) * 2));
    }
}

.col2 {
}

.whereToStayBlock {
    margin-top: px(550);

    @include bp(mobile) {
        margin: px(30) auto;
        width: calc(100% - calc(var(--col-size) * 2));
    }
}
