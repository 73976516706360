@import "shared";

.FaqAccordion {
    @include grid;
    position: relative;
    z-index: 2;
    margin: px(100) 0;
    padding-bottom: px(80);
}

.faqAccordion__list {
    grid-column: 4 / #{$grid-columns - 2};
    padding: 0;
    margin: 0;
    border: 1px solid $black;
    border-top: none;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        grid-column: 1 / 16;
        transform: translateX(var(--col-size-half));
    }
}

.faqAccordion__listItem {
}

.faqAccordion__listItemQuestion {
    @include h5;
    padding: px(7);
    height: px(100);
    border-top: 1px solid $black;
    background-color: $yellow;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    @include bp(mobile) {
        font-size: px(20);
    }
}

.faqAccordion__listItemQuestionText {
    max-width: 50%;

    @include bp(mobile) {
        max-width: 90%;
    }
}

.faqAccordion__listItemIcon {
    width: px(13);

    .active & {
        transform: rotate(180deg);
    }
}

.faqAccordion__listItemAnswer {
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: $off-white;
}

.faqAccordion__listItemAnswerInner {
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid $black;
    width: 100%;
    padding: px(23) px(7) px(62);
    pointer-events: none;

    .active & {
        pointer-events: all;
    }
}

.faqAccordion__listItemAnswerWidth {
    width: 60%;

    @include bp(mobile) {
        width: 90%;
    }
}
