@import "shared";

.titleListBlock {
    border: 1px solid $black;
    background-color: $off-white;
    width: 100%;
    height: fit-content;
    z-index: 2;
    position: relative;
}

.titleListBlock__transitionContainer {
    display: block;
}

.titleListBlock__title {
    padding: px(30) px(10) px(30) px(20);
    background-color: $off-white;
    @include body;
    font-weight: 700;
    border-bottom: 1px solid $black;

    .theme-green & {
        background-color: $green;
    }

    .theme-red & {
        background-color: $red;
        color: $white;
    }
}

.titleListBlock__list {
    @include body-small;
    padding: 0;
    margin: 0;
}

.titleListBlock__listItem {
    display: grid;
    grid-template-columns: px(40) 1fr;
    list-style: none;
}

.titleListBlock__listItem__number,
.titleListBlock__listItem__title {
    padding-top: px(5);
    padding-bottom: px(5);
}

.titleListBlock__listItem__number {
    text-align: center;
    width: 100%;

    &:after {
        content: ".";
        display: inline-block;
        margin-left: px(5);
    }
}

.titleListBlock__listItem__title {
    border-left: 1px solid $black;
    padding-left: px(30);
    padding-right: px(30);
}

.titleListBlock__footer {
    background-color: $black;
    @include eyebrow;
    text-transform: uppercase;
    color: $white;
    padding: px(13) px(20) px(30);
}

.titleListBlock__datesYear {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(15);
}

.titleListBlock__dates {
}

.titleListBlock__year {
}

.titleListBlock__location {
    text-align: center;
}
