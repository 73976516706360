@import "shared";

.BursariesHero {
    padding-top: px(108);
    position: relative;
}

.inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: px(170);
    margin-left: px(180);

    @include bp(mobile) {
        gap: 0;
        margin-left: 0;
        width: 100%;
        padding: 0 var(--col-size-half);
        flex-direction: column;
    }
}

.heroContainer {
    width: px(422);

    @include bp(mobile) {
        width: 100%;
    }
}

.heroBlock {
    opacity: 0;
}

.imageTopContainer {
    width: px(550);
    right: px(-50);
    top: px(-30);
    position: absolute;

    @include bp(mobile) {
        width: 100%;
        position: relative;
        margin-top: px(40);
        margin: 0;
        transform: none !important;
        overflow: hidden;
        right: calc(-1 * var(--col-size-half));
        top: auto;
    }
}

.imageTop {
    width: 100%;
    opacity: 0;

    @include bp(mobile) {
        width: px(298);
        margin-left: auto;
        margin-right: px(-30);
    }
}

.benefitsContainer {
    width: px(280);
    position: relative;
    margin-top: px(550);
    opacity: 0;
    z-index: 2;

    @include bp(mobile) {
        width: px(280);
        margin-left: auto;
        margin-top: px(70);
    }
}

.imageEquationContainer {
    left: -30%;
    width: 180%;
    top: 110%;
    position: absolute;

    @include bp(mobile) {
        position: static;
        width: 110%;
        margin-left: px(-30);
        margin-top: px(40);
    }
}

.imageEquation {
    opacity: 0;
}
