@import "shared";

.FixedNavigationSection {
    margin: px(116) 0;
    position: relative;
    @include z-index(FixedNavigationCta);

    @include bp(mobile) {
        margin: px(80) 0;
    }
}

.ctaContainer {
    --desktop-thumb-width: #{px(181)};
    --desktop-thumb-height: #{px(251)};

    padding: 0 var(--col-size-half);
    width: 100%;

    @include bp(mobile) {
        --desktop-thumb-height: #{px(90)};
        --desktop-thumb-width: #{px(150)};
    }

    &.static {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include bp(mobile) {
            align-items: flex-end;
            padding-right: 0;
        }
    }

    &.green {
        --fixed-nav-bg: #{$green};
        --fixed-nav-fg: #{$black};
    }

    &.red {
        --fixed-nav-bg: #{$red};
        --fixed-nav-fg: #{$off-white};
    }

    &.yellow {
        --fixed-nav-bg: #{$yellow};
        --fixed-nav-fg: #{$black};
    }

    &.blue {
        --fixed-nav-bg: #{$blue};
        --fixed-nav-fg: #{$off-white};
    }
}

.ctaContainer__textContainer {
    position: fixed;
    left: var(--col-size-half);
    bottom: var(--col-size-half);
    @include z-index(FixedNavigationCtaText);
    pointer-events: none;
    height: var(--desktop-thumb-height);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: var(--col-size-half);

    @include bp(mobile) {
        height: px(140);
        justify-content: flex-end;
        padding-bottom: var(--col-size-half);
    }

    .static & {
        position: static;
        pointer-events: all;
    }
}

.ctaContainer__text {
    @include h4;
    white-space: break-spaces;
    color: var(--fixed-nav-fg);
    opacity: 0;

    @include bp(mobile) {
        font-size: px(16);
    }
}

.ctaContainerThumbContainer {
    width: var(--desktop-thumb-width);
    height: var(--desktop-thumb-height);
    padding: px(11) px(12);
    position: fixed;
    right: var(--col-size-half);
    bottom: var(--col-size-half);
    @include z-index(FixedNavigationCta);
    color: var(--fixed-nav-fg);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .static & {
        position: static;

        @include bp(mobile) {
            transform: translateX(#{px(-12)});
        }
    }
}

.ctaContainer__bg {
    width: var(--desktop-thumb-width);
    height: var(--desktop-thumb-height);
    background-color: var(--fixed-nav-bg);
    right: var(--col-size-half);
    bottom: var(--col-size-half);
    position: fixed;
    @include z-index(FixedNavigationCta);
    border: 1px solid $black;
    border-radius: px(3);

    .static & {
        position: absolute;
        bottom: 0;
    }
}

.ctaContainerThumbContainer__top {
    white-space: break-spaces;
}

.ctaContainerThumbContainer__ctaContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ctaContainerThumbContainer__cta {
}

.ctaContainerThumbContainer__ctaTriangle {
    width: px(12);
}

.ctaContainerThumbContainer__highlight {
}

.positionContainer {
    height: var(--height);

    @include bp(mobile) {
        display: flex;
        width: 100%;
    }
}
