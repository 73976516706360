@import "shared";

.LogosSection {
    padding-bottom: px(80);
    margin-top: px(150);

    @include bp(mobile) {
        margin-top: px(100);
        padding-bottom: px(100);
    }

    * + .LogosSection {
        margin-top: px(50);

        @include bp(mobile) {
            margin-top: 0;
        }
    }
}

.inner {
}

.title {
    @include h4;
    margin-left: px(20);
    margin-bottom: px(40);

    @include bp(mobile) {
        margin-left: var(--col-size-half);
    }
}

.logoList {
    width: px(600);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: px(40);

    @include bp(mobile) {
        width: 100%;
        padding: 0 var(--col-size);
        grid-template-columns: repeat(2, 1fr);
        gap: px(20);
    }
}

.logoContainer {
    position: relative;
    height: px(50);
}

.logo {
    img {
        @include position-100(absolute);
        object-fit: contain;
    }
}
