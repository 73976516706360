@import "shared";

.videoThumb {
    opacity: 0;
    position: fixed;
    right: var(--col-size-half);
    bottom: var(--col-size-half);
    width: px(200);
    height: px(125);
    background-color: $black;
    @include z-index(VideoThumbnail);
    overflow: hidden;

    @include bp(mobile) {
        width: px(130);
        height: px(80);
    }

    video {
        @include position-100(absolute);
        object-fit: cover;
        transition: opacity 0.6s;
        height: 110%;
        top: -5%;
        cursor: pointer;
    }

    @include hover {
        video {
            opacity: 0.2;
        }
    }
}

.videoPopout {
    @include position-100(fixed);
    @include vh(100);
    @include z-index(VideoPopout);
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoPopoutBg {
    @include position-100(absolute);
    z-index: 1;
    background-color: rgba($black, 0.95);
}

.videoPopoutIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
}

.videoPopoutClose {
    position: absolute;
    top: var(--col-size);
    right: var(--col-size);
    z-index: 3;
    @include h5;
    color: $white;
    cursor: pointer;

    @include hover {
        color: $yellow;
    }
}
