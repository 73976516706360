@import "shared";

.SpeakersSection {
    position: relative;
    z-index: 2;
    margin: px(110) 0 px(210);

    --mobile-thumb-size: #{px(53)};

    @include bp(mobile) {
        padding-bottom: px(0);
        margin: px(50) 0 px(150);
    }
}

.title {
    @include h4;
    margin-left: var(--col-size-half);
    margin-bottom: px(40);

    @include bp(mobile) {
        display: none;
    }
}

.hoverImage {
    position: fixed;
    top: 0;
    left: 0;
    @include z-index(SpeakerHoverImage);
    width: px(250);
    height: px(250);
    pointer-events: none;
    border: 1px solid $black;
    transform-origin: bottom left;

    @include bp(mobile) {
        display: none;
    }

    img {
        @include position-100(absolute);
        object-fit: cover;
    }
}

.speakersSection__listContainer {
    @include grid;
    position: relative;
}

.speakersSection__topImage {
    position: absolute;
    top: px(-50);
    left: px(540);
    width: px(700);

    @include bp(mobile) {
        display: none;
    }
}

.speakersSection__bottomImage {
    position: absolute;
    top: 100%;
    margin-top: px(-350);
    left: px(900);
    width: px(340);

    @include bp(mobile) {
        width: px(114);
        left: px(164);
        margin-top: px(-70);
    }
}

.speakersSection__list {
    grid-column: 4 / #{$grid-columns - 2};
    padding: 0;
    margin: 0;
    border: 1px solid $black;
    border-top: none;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        grid-column: 1 / 16;
        transform: translateX(var(--col-size-half));
    }
}

.speakersSection__listItem {
    position: relative;
}

.speakersSection__listItemQuestion {
    @include h5;
    padding: px(7) px(20);
    height: px(70);
    border-top: 1px solid $black;
    background-color: $green;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    .speakersSection__listItem:not(.clickable) & {
        pointer-events: none;
    }

    @include bp(mobile) {
        font-size: px(16);
        height: var(--mobile-thumb-size);
        padding: 0;
        justify-content: flex-start;
    }
}

.speakersSection__listItemQuestionImageContainer {
    display: none;
    height: var(--mobile-thumb-size);
    min-width: var(--mobile-thumb-size);
    border-right: 1px solid $black;
    position: relative;

    @include bp(mobile) {
        display: block;
    }
}

.speakersSection__listItemQuestionImage {
    @include position-100(absolute);
    object-fit: cover;
}

.speakersSection__listItemQuestionText {
    max-width: 50%;

    .noPicture & {
        @include bp(mobile) {
            margin-left: calc(var(--mobile-thumb-size) + #{px(15)});
        }
    }

    @include bp(mobile) {
        max-width: 90%;
        margin-left: px(15);
        padding-top: px(7);
    }
}

.speakersSection__listItemIcon {
    position: absolute;
    top: px(7);
    right: px(7);
    width: px(13);

    @include bp(mobile) {
        top: px(9);
    }

    .active & {
        transform: rotate(180deg);
    }

    .speakersSection__listItem:not(.clickable) & {
        display: none;
    }
}

.speakersSection__listItemAnswer {
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: $off-white;
}

.speakersSection__listItemAnswerInner {
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid $black;
    width: 100%;
    padding: px(20);
    pointer-events: none;
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    gap: px(20);

    .noPicture & {
        grid-template-columns: 0.7fr 0.3fr;

        @include bp(mobile) {
            grid-template-columns: 1fr;
        }
    }

    @include bp(mobile) {
        grid-template-columns: 1fr;
        font-size: px(14);
    }

    .active & {
        pointer-events: all;
    }
}

.speakersSection__listItemPicture {
}

.speakersSection__listItemPictureImg {
    width: 100%;
}

.speakersSection__listItemAbout {
    p {
        margin: px(20) 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
