@import "shared";

$hero-height: 70;
$hero-height-mobile: 100;

.HomeHero {
    display: block;
    position: relative;
}

.grain {
    z-index: -1;
}

.verticalText {
    opacity: 0;
}

.heroInner {
    position: relative;
    width: 100%;
    padding: 0 var(--col-size-half);
    @include vh(100);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $yellow;
    z-index: 2;

    @include bp(mobile) {
        height: calc(var(--vh, 1vh) * 100);
        padding-top: calc(var(--header-height) * 2.8);
        padding-bottom: px(15);
    }
}

.heroTextImageContainer {
    width: 100%;
    @include vh($hero-height);
    position: relative;

    @include bp(mobile) {
        height: 100%;
    }
}

.heroTextImageContainerUnder {
    @include position-100(absolute);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroTextImageContainerOver {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    --intro-animation-top-x: 0%;
    --intro-animation-bottom-x: 0%;
    --scroll-animation-top-x: 0%;
    --scroll-animation-bottom-x: 0%;

    clip-path: polygon(
        0% 0%,
        var(--intro-animation-top-x) 0%,
        var(--intro-animation-bottom-x) 100%,
        0% 100%
    );

    .introAnimationComplete & {
        clip-path: polygon(
            var(--scroll-animation-top-x) 0%,
            100% 0%,
            100% 100%,
            var(--scroll-animation-bottom-x) 100%
        );
    }
}

.heroHeading {
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    mix-blend-mode: difference;
}

.heroHeadingBehind {
    position: relative;
}

.monthText,
.yearText,
.dateText {
    @include body-large;
    text-transform: uppercase;
    position: absolute;
    z-index: 2;
    mix-blend-mode: difference;

    &.isBehind {
        color: $black;
        mix-blend-mode: soft-light;
        opacity: 0;

        .introAnimationComplete & {
            opacity: 1;
        }
    }

    &.top {
        top: 28%;

        @include bp(mobile) {
            top: px(10);
        }

        &.isBehind {
            @include bp(mobile) {
                top: auto;
                bottom: 30%;
            }
        }
    }

    &.bottom {
        bottom: 28%;

        @include bp(mobile) {
            bottom: px(10);
        }
    }
}

.monthText {
    left: 8%;

    @include bp(mobile) {
        left: px(10);
    }
}

.dateText {
    left: 50%;
    transform: translateX(-50%);
}

.yearText {
    right: 9%;

    @include bp(mobile) {
        right: px(10);
    }
}

.title {
    @include h1;
    line-height: 1;

    @include bp(mobile) {
        text-align: center;
        line-height: 1;
    }
}

.titleBehind {
    color: $blue;
    opacity: 0;

    .introAnimationComplete & {
        opacity: 1;
    }
}

.heroImage {
    img {
        @include position-100(absolute);
        object-fit: cover;
    }
}

.bubbleTextOverContainer {
    @include position-100(absolute);
    overflow: hidden;
    color: $blue;
}

.bubbleTextUnderContainer {
    @include position-100(absolute);
    z-index: 1;
    color: $yellow;
    mix-blend-mode: color-burn;
}

.bubbleTextOver {
}

.bubbleTextUnder {
}

.bubbleText {
    position: absolute;
    top: 87%;
    right: 20%;
    z-index: 2;
    padding: px(60) px(50) px(35) px(50);
    width: px(400);
    text-align: center;
    mix-blend-mode: difference;
    @include h5;
    opacity: 0;

    @include bp(mobile) {
        width: px(250);
        right: px(10);
        padding: px(30) px(30) px(25) px(30);
        top: 80%;
        font-size: px(14);
    }
}

.bubbleTextCircle {
    @include position-100(absolute);
}

.rtaGrid {
    @include grid;
    transform: translateY(0vh);
    margin-top: -40vh;
    z-index: 5;
    position: relative;
    opacity: 0;

    @include bp(mobile) {
        margin-top: -20vh;
        display: block;
        width: 100%;
        padding: 0 var(--col-size-half);
    }

    .introAnimationComplete & {
        opacity: 1;
    }
}

.rta {
    grid-column: 4 / 15;
    @include body-large;
}
