@import "shared";

.ContactUsSection {
    padding: px(100) 0;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        padding: px(80) 0;
    }
}

.inner {
    padding: var(--col-size-half);
}

.title {
    @include h4;
}

.form {
    width: px(878);
    margin: px(40) auto;
    background-color: $off-white;
    padding: px(30) px(40);
    position: relative;
    margin-bottom: px(50);

    @include bp(mobile) {
        width: 100%;
    }
}

.formInner {
    .success & {
        opacity: 0;
        pointer-events: none;
    }
}

.buttons {
    margin-bottom: px(60);

    @include bp(mobile) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}

.recipientButton {
    @include reset-button;
    font-size: px(14);
    line-height: 1;
    text-transform: uppercase;
    padding: px(13) px(27);
    background-color: $off-white;
    border: 1px solid $black;

    @include bp(mobile) {
        width: px(200);
        text-align: center;
        padding-left: 0;
        padding-right: 0;

        &:not(:last-child) {
            margin-bottom: px(35);
        }
    }

    &:not(:last-child) {
        margin-right: px(35);
    }

    &.active {
        border-color: $yellow;
        background-color: $yellow;
    }
}

.recipientButtonText {
    position: relative;
}

.highlight {
    width: 130%;
}

.fields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: px(20);

    @include bp(mobile) {
        gap: 0;
    }

    input,
    textarea {
        background: transparent;
        border: none;
        border-bottom: 1px solid $black;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;

        &:focus {
            outline: 2px solid $black;
            border-bottom-color: transparent;
        }
    }

    input {
        height: px(50);
    }
}

.fieldsTop {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: px(20);
    margin-bottom: px(30);

    @include bp(mobile) {
        grid-template-columns: 1fr;
    }
}

.fieldContainer {
    width: 100%;

    @include bp(mobile) {
        margin-bottom: px(20);
    }
}

.sendButton {
    @include reset-button;
    height: px(40);
    width: px(160);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: $blue;
    color: $white;
    top: 100%;
    right: 0;
    position: absolute;

    @include bp(mobile) {
        width: 100%;
    }
}

.error {
    color: $red;
    line-height: 1;
    text-align: right;
    width: 100%;
    margin: 0;

    @include bp(mobile) {
        text-align: left;
    }
}

.successMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include body-large;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
