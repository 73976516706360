@import "shared";

.RichTextSection {
    margin: px(110) 0;
    padding-bottom: px(80);
    opacity: 0;
}

.inner {
    padding: 0 var(--col-size-half);
    max-width: px(700);
    margin-left: px(130);

    @include bp(mobile) {
        max-width: 100%;
        width: 100%;
        margin-left: 0;
    }
}

.heading {
    max-width: px(410);
    margin-bottom: px(50);

    @include bp(mobile) {
        margin-bottom: px(30);
        max-width: 100%;
        width: 100%;
    }
}

.rta {
    @include bp(mobile) {
        padding: 0 px(15);
    }
}
