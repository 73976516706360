@import "shared";

$border-size: px(20);

.RectangleText {
    @include h5;
    border: $border-size solid $red;
    color: $red;
    min-height: px(195);
    padding: $border-size;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: break-spaces;
    position: relative;
}

.icon {
    position: absolute;
    top: px(10);
    right: px(10);
    width: px(18);
}

.link {
    @include position-100(absolute);
    left: -$border-size;
    top: -$border-size;
    width: calc(100% + #{$border-size * 2});
    height: calc(100% + #{$border-size * 2});
    z-index: 2;
}

.linkText {
    margin-top: $border-size;
}

.linkArrow {
    display: block;
    height: px(32);

    @include bp(mobile) {
        height: px(30);
    }
}
